/*** 

====================================================================
	Google Auth Step
====================================================================

 ***/
 @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');


 .google-auth-step {
    position: relative;
    padding: 50px 40px;
  }
  
  .google-auth-step ul {
    position: relative;
  }
  
  .google-auth-step ul li {
    padding-left: 50px;
    position: relative;
  }
  
  .google-auth-step ul li:before {
    position: absolute;
    left: 0;
    top: 3px;
    width: 25px;
    height: 25px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #ffaa06;
    text-align: center;
    z-index: 1;
    color: #ffaa06;
  }
  
  .google-auth-step ul li:nth-child(1):before {
    content: "1";
  }
  
  .google-auth-step ul li:nth-child(2):before {
    content: "2";
  }
  
  .google-auth-step ul li:nth-child(3):before {
    content: "3";
  }
  
  .google-auth-step ul li:nth-child(4):before {
    content: "4";
  }
  
  .google-auth-step ul li:nth-child(5):before {
    content: "5";
  }
  
  .google-auth-step ul li:after {
    content: "";
    position: absolute;
    left: 12px;
    top: 29px;
    width: 1px;
    height: 114%;
    background-color: rgba(255, 255, 255, 0.4);
  }
  
  .google-auth-step ul li:last-child:after {
    display: none;
  }
  
  .steps-google {
    position: relative;
    margin-bottom: 60px;
  }
  
  .steps-google .top-area {
    position: relative;
    margin-bottom: 25px;
  }

  .steps-google .top-area h4{
    font-family: 'oswald'!important;
  }
  
  .steps-google .top-area p {
    margin-bottom: 17px;
  }
  
  .steps-google .top-area a {
    margin-right: 12px;
  }

  .steps-google .top-area a img {
    border-radius: 10px;
  }
  
  .steps-google .bottom-area {
    position: relative;
  }
  
  .steps-google .bottom-area a {
    display: inline-block;
  }
  
  .steps-google .bottom-area p {
    display: inline-block;
    margin-left: 50px;
    font-size: 16px;
  }
  
  .qr-box {
    position: relative;
    margin-top: 30px;
    clear: both;
    display: flex;
  }
  
  .qr-box .qr-area-box {
    position: relative;
    width: 250px;
    height: 250px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    padding: 24px 20px;
    margin-right: 30px;
  }
  
  /* .qr-box .qr-area-box:last-child {
    background-color: #f1f0f9;
  } */
  
  .qr-box .qr-area-box p {
    margin-top: 10px;
  }
  
  .qr-box .qr-area-box h4 {
    font-size: 18px;
    margin-bottom: 80px;
    color: #1b1b27;
  }
  
  .steps-google .top-area h5 {
    margin: 25px 0;
    font-size: 15px;
  }
  
  .steps-google .top-area .note {
    color: red;
    margin-left: 30px;
  }
  
  .steps-google .top-area .note .icon {
    position: absolute;
    left: 0;
  }
  
  .steps-google .top-area {
    clear: both;
    width: 100%;
    margin-top: 40px;
  }
  
  .steps-google .top-area .group-form {
    margin-bottom: 25px;
  }

  .steps-google .top-area .group-form .google-input-field 
  .MuiInputBase-root {
    color: #ffffff;
  }
  
  .steps-google .top-area .group-form .google-input-field 
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
  }
  
  .steps-google .top-area .group-form .google-input-field 
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ffaa06;
  }
  
  .steps-google .top-area .group-form .google-input-field 
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #ffaa06;
  }
  
  /* .steps-google .top-area .group-form .control-form {
    width: 60%;
    padding: 14px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: none;
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
  } */

 
  @media(min-width: 768px) and (max-width: 1024px) {
    .steps-google .btn-style-four {
      padding: 10px 54px;
      font-size: 14px;
    }
  
  }
  @media(max-width: 768px){
    .steps-google .btn-style-four {
      padding: 7px 44px;
      font-size: 12px;
    }
    .steps-google .top-area a img{
      margin-top: 10px;
    }
  }

  @media(min-width: 320px) and (max-width: 374px) {
    .steps-google .top-area .group-form .google-input-field .MuiOutlinedInput-input {
      padding: 14.5px 10px;
    }
  }

  @media(min-width: 320px) and (max-width: 374px) {
    .steps-google .bottom-area p{
      margin-left: 10px;
    }
    .steps-google .top-area a img{
      margin-top: 10px;
    }
    .steps-google .btn-style-four {
      padding: 7px 69px;
    }
 
  }