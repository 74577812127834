/*** 

====================================================================
			Fonts
====================================================================

***/


@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
/* 

font-family: 'Roboto', sans-serif;
font-family: 'Lato', sans-serif;

*/


@import url('fontawesome-all.css');
@import url('animate.css');
@import url('hover.css');

/*** 

====================================================================
	Reset
====================================================================

 ***/

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

/*** 

====================================================================
	Global Settings
====================================================================

***/


body {
  font-family: 'Montserrat' !important;
  font-size: 14px;
  color: #0a0a12;
  line-height: 28px;
  font-weight: 400;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

body.black {
  background: #000;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #2154cf;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  font-family: 'Montserrat' !important;
}

button {
  cursor: pointer;
  text-decoration: none;
  outline: none !important;
   font-family: 'Montserrat' !important;
}


a:hover,
a:focus,
a:visited {
  text-decoration: none !important;
  outline: none !important;
  font-family: 'Montserrat' !important;
}


h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.2em;
   font-family: 'Montserrat' !important;
}
h1,
h2{
  position: relative;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.2em;
  font-family: 'Oswald' !important;
}

input,
button,
select,
textarea {
   font-family: 'Montserrat' !important;
}

textarea {
  overflow: hidden;
  resize: none;
}

figure {
  margin: 0;
  padding: 0;
   font-family: 'Montserrat' !important;
}

p,
.text {
  position: relative;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  color: #777777;
  margin: 0;
   font-family: 'Montserrat' !important;
}

::-webkit-input-placeholder {
  color: inherit;
   font-family: 'Montserrat' !important;
}

::-moz-input-placeholder {
  color: inherit;
   font-family: 'Montserrat' !important;
}

::-ms-input-placeholder {
  color: inherit;
   font-family: 'Montserrat' !important;
}

.dark {
  background: #21212f;
  color: white;
  min-height: 100vh;
   font-family: 'Montserrat' !important;
}

.dark .table {
  color: white;
   font-family: 'Montserrat' !important;
}

.page-wrapper-inner {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  color: white;
  min-width: 300px;
  overflow: hidden;
   font-family: 'Montserrat' !important;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
   font-family: 'Montserrat' !important;
}

img {
  display: inline-block;
  max-width: 100%;
   font-family: 'Montserrat' !important;
}

.theme-btn {
  display: inline-block;
  text-align: center;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  font-family: 'Montserrat' !important;
}

.centered {
  text-align: center !important;
   font-family: 'Montserrat' !important;
}

/*Btn Style One*/

.btn-style-one {
  position: relative;
  font-size: 13px;
  line-height: 30px;
  color: #fff;
  padding: 10px 44px;
  text-transform: capitalize;
  font-weight: 600;
  border-radius: 50px;
  background-image: linear-gradient(#ffaa06, #ffaa06);
  display: inline-block;
  font-family: 'Montserrat' !important;
}

.btn-style-one:hover {
  color: #fff !important;
  -webkit-box-shadow: #ffaa06 0 0px 0px 40px inset;
  -moz-box-shadow: #ffaa06 0 0px 0px 40px inset;
  -ms-box-shadow: #ffaa06 0 0px 0px 40px inset;
  -o-box-shadow: #ffaa06 0 0px 0px 40px inset;
  box-shadow: #ffaa06 0 0px 0px 40px inset;
}

.btn-style-one:before {
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  bottom: -1px;
  content: "";
  border-radius: 50px;
  transition: all 300ms ease;
  opacity: 0;

}

.btn-style-one:hover:before {
  opacity: 1;
}

/*Btn Style Two*/

.btn-style-two {
  position: relative;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
  padding: 15px 44px;
  text-transform: capitalize;
  font-weight: 400;
  border-radius: 50px;
  background-color: #fa6634;
  display: inline-block;
   font-family: 'Montserrat' !important;

}

.btn-style-two:hover {
  color: #fff;
  -webkit-box-shadow: rgba(10, 21, 169, 1) 0 0px 0px 40px inset;
  -moz-box-shadow: rgba(10, 21, 169, 1) 0 0px 0px 40px inset;
  -ms-box-shadow: rgba(10, 21, 169, 1) 0 0px 0px 40px inset;
  -o-box-shadow: rgba(10, 21, 169, 1) 0 0px 0px 40px inset;
  box-shadow: rgba(10, 21, 169, 1) 0 0px 0px 40px inset;
}

.btn-style-two:before {
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  bottom: -1px;
  content: "";
  border-radius: 50px;
  transition: all 300ms ease;
  opacity: 0;
}

.btn-style-two:hover:before {
  opacity: 1;
}


/*Btn Style Two*/

.btn-style-three {
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  padding: 9px 54px;
  text-transform: capitalize;
  font-weight: 400;
  border-radius: 50px;
  background-color: #ffaa06;
  display: inline-block;
   font-family: 'Montserrat' !important;

}

.btn-style-three:hover {
  color: #ffaa06;
  -webkit-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
  -moz-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
  -ms-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
  -o-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
  box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
}

.btn-style-three:before {
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  bottom: -1px;
  content: "";
  border-radius: 50px;
  transition: all 300ms ease;
  opacity: 0;
}

.btn-style-three:hover:before {
  opacity: 1;
}

.btn-style-four{
	position:relative;
	font-size:16px;
	line-height:30px;
	color:#ffaa06;
	padding: 9px 54px;
	text-transform: capitalize;
	font-weight: 400;
	border-radius: 50px;
	border:1px solid #ffaa06;
	background: none;
	display: inline-block;
   font-family: 'Montserrat' !important;
}

.btn-style-four:hover{
	background-color: #ffaa06;
	color: #ffffff;
	-webkit-box-shadow: #ffaa06 0 0px 0px 40px inset;
	-moz-box-shadow: #ffaa06 0 0px 0px 40px inset;
	-ms-box-shadow: #ffaa06 0 0px 0px 40px inset;
	-o-box-shadow: #ffaa06 0 0px 0px 40px inset;
	box-shadow: #ffaa06 0 0px 0px 40px inset;
}

.light .btn-style-four:hover{
	background-color: #ffaa06;
	color: #ffffff;
	-webkit-box-shadow: #ffaa06 0 0px 0px 40px inset;
	-moz-box-shadow: #ffaa06 0 0px 0px 40px inset;
	-ms-box-shadow: #ffaa06 0 0px 0px 40px inset;
	-o-box-shadow: #ffaa06 0 0px 0px 40px inset;
	box-shadow: #ffaa06 0 0px 0px 40px inset;
}

.btn-style-four:before{
	position: absolute;
	top: -1px;
	right: -1px;
	left: -1px;
	bottom: -1px;
	content: "";
	border-radius: 50px;
	transition: all 300ms ease;
	opacity: 0;
  font-family: 'montserrat';
}

.btn-style-four:hover:before{
	opacity: 1;
}

.theme_color {
  color: #7b8ec6;
   font-family: 'Montserrat' !important;
}

.pull-right {
  float: right;
   font-family: 'Montserrat' !important;
}

.pull-left {
  float: left;
   font-family: 'Montserrat' !important;
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../../static/images/icons/preloader.svg);
   font-family: 'Montserrat' !important;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.outer-container {
  position: relative;
  padding: 0px 15px;
  max-width: 1540px;
  margin: 0 auto;
   font-family: 'Montserrat' !important;
}


body::-webkit-scrollbar {
  width: 3px;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #21212f;
  outline: 1px solid #0a0a12;
}

/*** 

====================================================================
	Group Table Area
====================================================================

 ***/
.group-table-area {
  position: relative;
  padding: 60px 0;
}

.group-table-area .dash-top-title {
  padding: 15px 40px 20px;
}

.group-table-area .dash-top-title h2 {
  display: inline-block;
}

.group-table-area .dash-top-title a {
  float: right;
  border: 1px solid #fff;
  padding: 1px 35px;
  border-radius: 45px;
  color: #fff;
}

.group-table-area .group-main-area {
  background-color: #1b1b27;
  padding: 55px 40px;
}

.group-table-area .group-main-area .table {
  border: 1px solid #fff;
}

.group-table-area .group-main-area .table td {
  vertical-align: middle;
}

.group-table-area .group-main-area .table thead th {
  border: 0;
  background-color: #ffaa06;
}

.group-table-area .group-main-area .table .icn-box {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.group-table-area .group-main-area .table .icn-box i {
  display: block;
  position: absolute;
  top: -8px;
}

.group-table-area .group-main-area .table .icn-box i:first-child {
  line-height: 0px;
  position: absolute;
  top: -9px;
}

.group-table-area .group-main-area .table td .group-icon {
  margin-right: 11px;
}

.group-table-area .group-main-area .table .active-status {
  width: 10px;
  height: 10px;
  background-color: #58a25e;
  position: relative;
  display: inline-block;
  margin-right: 5px;
  border-radius: 50%;
}


/*** 

====================================================================
	group page3 Section
====================================================================

 ***/

.group-page3-section {
  position: relative;
  padding: 60px 0;
}


.group-page3-section .detail-area {
  position: relative;
  background: #1b1b27;
}

.group-page3-section .detail-area .boxes {
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 12px 15px;
}

.group-page3-section .detail-area .boxes:last-child {
  border-bottom: none;
}

.group-page3-section .detail-area .boxes h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.group-page3-section .detail-area .boxes h4 span {
  font-size: 14px;
}

.group-page3-section .detail-area .boxes p {
  color: #fff;
}

.group-page3-section .detail-area .boxes h5 {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
}

.group-page3-section .detail-area .boxes a {
  float: right;
  color: #fff;
  padding: 1px 29px;
  border-radius: 25px;
  margin-top: -19px;
  margin-right: 30px;
  text-transform: uppercase;
}

.group-page3-section .detail-area .boxes .white-btn {
  border: 1px solid #fff;
}

.group-page3-section .detail-area .boxes.status h5 {
  font-size: 12px;
}

.group-page3-section .detail-area .boxes.status h5 span {
  width: 10px;
  height: 10px;
  background: red;
  display: inline-block;
  border-radius: 50%;
  margin: 0 4px 0px 9px;
}

.group-page3-section .detail-area .boxes.status h5 i {
  font-style: normal;
}

.group-page3-section .detail-area .boxes .orenge-btn {
  background: #ffaa06;
}

.group-page3-section .search-form {
  float: right;
  margin-left: 33px;
  position: relative;
}

.group-page3-section .search-form .form-group {
  width: 100%;
  position: relative;
}

.group-page3-section .search-form .form-group .control-form {
  width: 210px;
  background: none;
  color: #fff;
  font-size: 14px;
  border: 1px solid #fff;
  background: none;
  border-radius: 40px;
  padding: 3px 25px;
  position: relative;
}

.group-page3-section .search-form .form-group button {
  position: absolute;
  background: no-repeat;
  color: #fff;
  right: 17px;
  top: 4px;
}

.group-page3-section .management-table .dash-top-title h2 {
  display: inline-block;
}

.group-page3-section .management-table {
  position: relative;
  background: #1b1b27;
}

.group-page3-section .management-table .tables {
  padding: 50px 25px;
  min-height: 544px;
}

.group-page3-section .management-table .table thead th {
  border-bottom: 0;
  border-top: 0;
}

.group-page3-section .management-table .table .icn-box {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.group-page3-section .management-table .table .icn-box i {
  display: block;
  position: absolute;
  top: -8px;
  color: #fff;
}

.group-page3-section .management-table .table .icn-box i:first-child {
  line-height: 0px;
  position: absolute;
  top: -9px;
}

/*** 

====================================================================
	devinded table
====================================================================

 ***/

.devinded-table {
  margin-top: 30px;
  position: relative;
  background: #1b1b27;
}

.devinded-table .tables {
  padding: 25px;
}

.devinded-table .table {
  text-align: center;
}

.devinded-table .table thead th {
  border-top: 0;
  border-bottom: 0;
}

.devinded-table .table .icn-box {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.devinded-table .table .icn-box i {
  display: block;
  position: absolute;
  top: -8px;
  color: #fff;
}

.devinded-table .table .icn-box i:first-child {
  line-height: 0px;
  position: absolute;
  top: -9px;
}

.devinded-table .table td {
  border-top: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130,138,145,.0) !important;
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border: none !important;
}

.btn-secondary.focus, .btn-secondary:focus {
  color: #fff;
  border: none !important;
  box-shadow: 0 0 0 0.2rem rgba(130,138,145,.0) !important;
}

/*Coin Table*/

.left-side-bar .table {
  overflow: auto;
  height: 45vh;
}

/*Light Version*/

.light {
  background: #fff;
  color: #23334c;
}

.light .inner-header .header-upper,
.light .nav-inner .right-area ul>li>ul {
  background-color: #eee;
}

.light .inner-menu .navigation>li>a,
.light .nav-inner .right-area ul li a,
.light .nav-inner .right-area ul>li>span,
.light .search-box .form-group button,
.light .search-box .form-group input[type="search"],
.light .left-side-bar .nav-tabs .nav-link,
.light .left-side-bar .table-dark td,
.light .left-side-bar .table-dark th,
.light .left-side-bar .table-dark thead th,
.light .graph-sec .top-area ul li,
.light .right-check-area .form-check input[type=checkbox],
.light .right-check-area .form-check input[type=radio],
.light .form-check-label,
.light .date-sec ul li a,
.light .order-detail .inner-col p,
.light .form-check-inline label.form-check-label,
.light .table-area .table-dark td,
.light .table-area .table-dark th,
.light .table-area .table-dark thead th,
.light .inner-form-block ul.limint-btn li a,
.light .form-sell-buy ul.rate-area li,
.light .form-sell-buy .form-area label,
.light h1,
.light h2,
.light h3,
.light h4,
.light h5,
.light h6,
.light p,
.light .withdraw-manag-sec .withdraw-form .form-area .title-area a,
.light .withdraw-manag-sec .withdraw-form .form-area .form-group .control-form,
.light .table td,
.light .table th,
.light .table-area .search-table .table td .icon,
.light .top-search-area form .form-group .control-form,
.light .list-wishlist li a,
.light .deposit-table .table td.light,
.light .deposit-area .nav-deposit li a,
.light .deposit-area .search-form .form-group .control-form,
.light .deposit-area .search-form .form-group button,
.light .deposit-area .history-deposit h5,
.light .deposit-area .history-deposit .table .icn-box i,
.light .balnce-table-sec .balance-table .table .icn-box i,
.light .deposit-area .history-deposit .table td a.wallet-table-btn,
.light .steps-google .top-area .google-fomr .group-form .control-form,
.light .group-page3-section .detail-area .boxes p,
.light .group-page3-section .management-table .table .icn-box i,
.light .sms-anthtication .sms-anthtication-form .form-area .sms-input-field .MuiInputBase-root,
.light .sms-anthtication .notice-bottom p,
.light .withdraw-balance .control-form {
  color: #23334c !important;
}

.light .history-sec ul.orderlist li a {
  border-color: #545b62;
  color: #545b62 !important;
}


.light .deposit-area .history-deposit .table td a.wallet-table-btn:hover {
  color: #ed6b1d;
}

.nav-inner .right-area ul li a {
  padding: 24px 15px;
}
.inner-menu .navigation > li > a:hover,
.nav-inner .right-area ul li a:hover,
.nav-inner .right-area ul li.current a{
  background-color: rgba(0,0,0,0.1);
  border-bottom: 1px solid #ffaa06;
}

.light .nav-inner .right-area ul>li.lang {
  border-left-color: #23334c;
}

.light .nav-inner .right-area ul>li>ul {
  border-top-color: #23334c;
}

.light .balnce-table-sec .balance-table .table thead th {
  border-bottom: #23334c;
}

.light .left-side-bar,
.light .left-side-bar .table-dark,
.light .graph-sec,
.light .history-sec,
.light .orders-sec .group-price-bock,
.light .table-area .table-dark,
.light .table-dark.table-striped tbody tr:nth-of-type(odd),
.light .balnce-table-sec .balance-table .table-striped tbody tr:nth-of-type(odd),
.light .form-sec-buy {
  background-color: #f7f7f7 !important;
}

.light .left-side-bar .table-dark.table-striped tbody tr:nth-of-type(odd),
.light .sms-anthtication .iti__country-list,
.light .balnce-table-sec .balance-table .table-striped tbody tr {
  background-color: #e9e9eb !important;
}

.light .left-side-bar .nav-tabs,
.light .sms-anthtication .bottom-btn {
  background-color: #ebebed;
}

.light .left-side-bar .table-dark td,
.light .left-side-bar .table-dark th,
.light .left-side-bar .table-dark thead th,
.light .table-area .table-dark td,
.light .table-area .table-dark th,
.light .table-area .table-dark thead th,
.light .table-area .search-table .table td,
.light .deposit-table .table td,
.light .devinded-table .table td,
.light .group-page3-section .detail-area .boxes,
.ligth .withdraw-balance .control-form {
  border-color: #e9e9eb !important;
}

.light .deposit-area .history-deposit .table thead th {
  border-color: #ffaa06;
}


.light .date-sec ul li a,
.light .orders-sec .group-price-bock ul.grap-btn li,
.light .inner-form-block ul.limint-btn li a,
.light .withdraw-manag-sec .withdraw-form .form-area .form-group .control-form,
.light .table-area .search-table .table td .icon,
.light .table-area .search-table .table .custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.light .table-area .search-table .table .custom-control-input:checked~.custom-control-label::before,
.light .top-search-area form .form-group .control-form,
.light .sms-anthtication .sms-anthtication-form .form-area .sms-input-field 
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.ligth .withdraw-balance .group-form .amount-fees,
.light .withdraw-balance .control-form {
  border-color: #ffaa06;
}

.light .date-sec,
.light .table-area .table-dark .active,
.light .withdraw-manag-sec .withdraw-form,
.light .table-area .search-table,
.light .deposit-table .table td,
.light .deposit-area .nav-deposit li a,
.light .deposit-area .deposit-inner,
.light .sms-anthtication .sms-anthtication-form,
.light .group-table-area .group-main-area,
.light .group-page3-section .detail-area,
.light .group-page3-section .management-table,
.light .devinded-table {
  background-color: #f9f9f9;
  box-shadow: 0 4px 29px rgba(47,47,47,.1);
}

.light .orders-sec .group-price-bock .group-btn select.mdb-select.md-form {
  background: none !important;
  border-color: #ef6c1a;
  color: #ef6c1a;
}

.light .orders-sec .group-price-bock .group-btn p {
  color: #ef6c1a;
}

.light .inner-form-block ul.limint-btn li .mdb-select,
.light .form-sell-buy .form-area input[type="number"] {
  background: none !important;
  border-color: #23334c;
  color: #23334c;
}

.light .form-sell-buy .form-check-inline .form-check-input:before,
.light .table-area .search-table .table .custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.light .table-area .search-table .table .custom-control-input:checked~.custom-control-label::before {
  background-color: #23334c;
}

.light .history-sec ul.orderlist li a:hover,
.light .history-sec ul.orderlist li.active a,
.light .date-sec ul li a:hover {
  color: #ffaa06;
}

.light .history-sec ul.orderlist li a:hover,
.light .history-sec ul.orderlist li.active a,
.light .date-sec ul li a:hover {
  border-color: #ffaa06;
}

.light .check {
  border: 5px solid #0e2745;
}

.light .check::before {
  background-color: #0e2745;
}

.light .dash-top-title,
.light .ddChild,
.light .btn-style-three,
.light .google-auth-step ul li:after,
.light .deposit-table .table thead th,
.light .deposit-area .nav-deposit li a:hover,
.light .deposit-area .nav-deposit li.active a,
.light .group-table-area .group-main-area .table thead th {
  background-color:#ffaa06;
  color: white !important;
}

.light .google-auth-step ul li:before, 
.light .google-auth-step ul li.active:before {
  background-color: #fff !important;
  border: 1px solid #ffaa06;
}

.light .btn-style-four {
  color: #ffaa06;
}

.light .btn-style-four,
.light .steps-google .top-area .group-form .google-input-field 
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #ffaa06;
}

.light .auth-btn {
  background-color: #ffffff;
	color: #ffaa06;
	-webkit-box-shadow: #ffffff 0 0px 0px 40px inset;
	-moz-box-shadow: #ffffff 0 0px 0px 40px inset;
	-ms-box-shadow: #ffffff 0 0px 0px 40px inset;
	-o-box-shadow: #ffffff 0 0px 0px 40px inset;
	box-shadow: #ffffff 0 0px 0px 40px inset;
}

.light .submit-auth-btn {
  background-color: #ffaa06;
  color: #ffffff;
}

.light .submit-auth-btn:hover {
  border: none;
  color: #ffaa06;
  background-color: #ffffff;
  -webkit-box-shadow: 0 4px 29px rgba(47,47,47,.1);
  -moz-box-shadow: 0 4px 29px rgba(47,47,47,.1);
  -ms-box-shadow: 0 4px 29px rgba(47,47,47,.1);
  -o-box-shadow: 0 4px 29px rgba(47,47,47,.1);
  box-shadow: 0 4px 29px rgba(47,47,47,.1);
}

.light .steps-google .top-area .group-form .google-input-field 
.MuiInputBase-root {
    color: #000000 !important;
  }

.light .ddChild ul li,
.light .table thead th,
.light .google-auth-step ul li:before,
.light .group-table-area .group-main-area .table thead th {
  color: #000;
}

/* Wallet Tables Font Colors */

.table-sec .table-clr td, .table-clr th {
  color: #fff;
}

.light .table-clr thead th {
  color: #000;
}

.light .deposit-area .qr-sec .card-header,
.light .deposit-area .qr-sec .bottom-note,
.light .deposit-area .history-deposit {
  background-color: #fff;
}

.light .deposit-area .search-form .form-group .control-form {
  background: none;
}

.light .google-auth-step ul li.active:before {
  background: #ffaa06;
}

.light .balnce-table-sec .balance-table .table td a {
  color: black;
  border: 1px solid rgb(92, 11, 73);
}

.light .withdraw-balance .group-form .amount-fees {
  border-bottom: 1px solid #23334c;
}

.dark .table-area .table-dark tr.active th, .dark .table-area .table-dark tr.active td {
  color: #fff !important;
}

.light .anti-phishing-modal .modal-content{
  background-color: #f9f9f9;
}

.light .anti-phishing-modal .modal-content .modal-body .validator-form .anti-phishing-input-field .MuiOutlinedInput-input {
  color: #000;
}

.light .dash-top-title h2{
  color: #fff !important;
}

.qr-box .qr-area-box{
  background-color:#ccc;
}

.light .btn-style-three {
  border: 1px solid;
}
.light .btn-style-three:hover {
  color: #ffaa06;
  -ms-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
  -o-box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
  box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
  color: #ffaa06 !important;
  border: 1px solid;
}

.signup-page .jss1 .MuiInputBase-input{
  color: #000 !important;
}
.jss1 .MuiInputBase-input {
  color: #000 !important;
}